import React from 'react';
import Layout from '../layout'
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Seo from '../seo';
import Header from '../../components/Header/newheader';
import { Link } from 'gatsby';
import SubHeader from '../SubHeader';


export const query = graphql`
query($slug: String ){
    allDatoCmsProducto(filter:{slug:{eq:$slug}}){
      nodes{
          slug
        titulo
        contenido
        especificacion{
          fluid(maxWidth:1200){
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`;


const Especifi = ({ data: { allDatoCmsProducto: { nodes } } }) => {


  const { titulo,  especificacion, slug } = nodes[0];
  let espevalor = false;
  if (especificacion)
    espevalor = true;
  return (
    <Layout>
      <Seo title="Madtronic" description="Venta" />

      <Header />
      <section>
        <div className="product-quickLink">
          <SubHeader slug={slug} />
          <div className="PathRoute">
            <Link to="/" className="click">Home  </Link>
            <a className="list-item-none">{titulo}</a>
            <a className="list-item-none">Especificaciones</a>
          </div>


          <div className="especi-img" style={{textAlign:"center", margin:"auto"}}>
            <div >
              {
                espevalor && <Image fluid={especificacion.fluid} />
              }

            </div>

          </div>
        </div>



      </section>
    </Layout>
  );
};

export default Especifi;